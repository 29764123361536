<template>
	<div class="container" v-show="pageShow" ref="container">
		<div class="page-header" ref="page-header">
			<div class="row cb top">
				<div class="row cc subject-name" @click="openSubjectSelect()">
					<span>{{ majorInfo.major_name || '全部科目' }}</span>
					<van-image class="arrow-down" :src="require('@/assets/arrow-down.png')" fit="cover" />
				</div>
				<!-- <div class="row cc right-view">
					<div class="row cc notice" @click="navMsg()">
						<van-image class="ding" :src="require('@/assets/ding.png')" fit="cover" />
						<div class="dot" v-show="userInfo.have_unread == 1"></div>
					</div>
					<div class="row cc mine" @click="navUserHome()">
						<span class="row cc">我的</span>
						<van-image class="avatar" :src="userInfo.avatar" fit="cover" round />
					</div>
				</div> -->
				<div class="row cc right-view">
					<div class="row cc notice" @click="navSearch()"><van-image class="search" :src="require('@/assets/search.png')" fit="cover" /></div>
				</div>
			</div>
			<!-- <div class="row search-view">
				<van-image class="search-img" :src="require('@/assets/search.png')" fit="cover" />
				<input type="text" placeholder="请输入要搜索的关键词" v-model="keywords" @keyup.enter="confirmSearch()" />
			</div> -->
			<van-swipe class="swipe-view" :autoplay="3000" indicator-color="white" ref="van-swipe">
				<template v-for="(item, index) in bannerList">
					<van-swipe-item @click="navUrl(item.url)"><van-image class="img" :src="$store.getters.oss(item.image)" fit="cover" /></van-swipe-item>
				</template>
			</van-swipe>
		</div>
		<div>
			<div ref="page-list">
				<van-sticky :container="stickyContainer">
					<div class="row tab-view" ref="tab-view">
						<div class="col cc tab-item" :class="{ active: tabIndex == 'newest' }" @click="tabChange('newest')">最新</div>
						<div class="col cc tab-item" :class="{ active: tabIndex == 'answered' }" @click="tabChange('answered')">已解答</div>
						<div class="col cc tab-item" :class="{ active: tabIndex == 'followed' }" @click="tabChange('followed')">关注</div>
						<div class="col cc tab-item" :class="{ active: tabIndex == 'unanswered' }" @click="tabChange('unanswered')">待解答</div>
					</div>
					<div class="row tab-view" ref="tab-view" v-if="false">
						<div class="col cc tab-item" :class="{ active: tabIndex == 3 }" @click="tabChange(3)">最新</div>
						<div class="col cc tab-item" :class="{ active: tabIndex == 1 }" @click="tabChange(1)">推荐</div>
						<div class="col cc tab-item" :class="{ active: tabIndex == 0 }" @click="tabChange(0)">关注</div>
						<div class="col cc tab-item" :class="{ active: tabIndex == 2 }" @click="tabChange(2)">悬赏</div>
						<div class="row cc right" v-show="tabIndex == 2">
							<van-popover placement="bottom-end" v-model="showPopover" trigger="click" :actions="awardType" @select="onSelect">
								<template #reference>
									<div class="row cc">
										<van-image class="arrow-down" :src="require('@/assets/arrow-down-gray.png')" fit="cover" />
										<span>全部</span>
									</div>
								</template>
							</van-popover>
						</div>
					</div>
				</van-sticky>
				<div>
					<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :style="{ 'min-height': headerHeight }" v-if="headerHeight">
						<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
							<div class="list">
								<template v-for="(item, index) in list">
									<answer :item="item" v-if="item.show_type == 1"></answer>
									<que :item="item" v-else-if="item.show_type == 2"></que>
									<reward :item="item" v-else></reward>
								</template>
							</div>
						</van-list>
					</van-pull-refresh>
				</div>
				<!-- <div><van-empty description="选择问题分类" /></div> -->
			</div>
		</div>
		<!-- <div class="row cc create-ask"><van-image class="img" :src="require('@/assets/edit.png')" fit="cover" @click="navAddAsk()" /></div> -->
		<!-- 科目选择 -->
		<subject-select ref="subjectSelect" @confim="onSubConfim($event)"></subject-select>
		<!-- 认证提醒 -->
		<van-popup v-model="authShow" style="border-radius: 0.32rem;">
			<div class="col auth-view">
				<div class="row cc header"><van-image class="img" :src="require('@/assets/auth.png')" fit="cover" /></div>
				<div class="col cb auth-main">
					<div class="col cc desc">
						<div class="title">认证医学专业用户</div>
						<div class="subtitle">答题瓜分现金悬赏</div>
					</div>
					<div class="col cc bottom">
						<div class="row cc btn" @click="navAuth()">立即认证</div>
						<div class="row cc cancel" @click="authShow = false">稍后再说</div>
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { ImagePreview } from 'vant';
import { mapState } from 'vuex';

import que from '@/components/list/que.vue';
import answer from '@/components/list/answer.vue';
import reward from '@/components/list/reward.vue';
import subjectSelect from '@/components/subject-select/subject-select.vue';
export default {
	data() {
		return {
			pageShow: false,
			showPopover: false,
			tabIndex: 'newest',
			page: 0,
			pageSize: 15,
			list: [],
			loading: false,
			finished: false,
			refreshing: false,
			headerHeight: '',
			authShow: false,
			stickyContainer: '',
			replyStatus: [{ text: '无回答', value: 0 }, { text: '有回答', value: 1 }, { text: '未采纳', value: 2 }, { text: '已采纳', value: 3 }],
			awardType: [{ text: '积分悬赏', value: 1 }, { text: '现金悬赏', value: 2 }],
			filters: {},
			bannerList: [],
			userInfo: {},
			keywords: ''
		};
	},
	components: { que, answer, reward, subjectSelect },
	created() {},
	async activated() {
		if (!this.pageShow) {
			this.$toast.loading({
				duration: 0,
				message: '加载中...',
				forbidClick: true
			});
		}
		let bannerRes = await this.$service.post('home/rotationMap');
		this.bannerList = bannerRes.data;
		this.mpLogin().then(async res => {
			let userInfo = await this.$service.post('user_info/detail');
			this.userInfo = userInfo.data;
			if (userInfo.data.mobile == '') {
				this.$dialog
					.confirm({
						title: '请绑定手机号码',
						message: '绑定账号互动信息，避免丢失',
						confirmButtonText: '去绑定',
						confirmButtonColor: '#03D3BC',
						cancelButtonText: '稍候绑定'
					})
					.then(() => {
						this.$router.push({
							path: '/bind'
						});
					})
					.catch(() => {});
			} else if (userInfo.data.is_cert == 0) {
				// this.authShow = true;
			}
			this.pageShow = true;
			this.$toast.clear();
			this.$nextTick(() => {
				let otherHeight = 10 + this.$refs['tab-view'].offsetHeight + this.$refs['page-header'].offsetHeight + 'px';
				this.headerHeight = `calc(100vh - ${otherHeight})`;
				this.onLoad();
				// 轮播图滑动报错
				this.$refs['van-swipe'].resize();
			});
		});
	},
	computed: {
		...mapState(['majorInfo'])
	},
	mounted() {},
	methods: {
		confirmSearch() {
			this.$router.push({
				path: '/search',
				query: {
					keywords: this.keywords
				}
			});
		},
		navSearch() {
			this.$router.push({
				path: '/search'
			});
		},
		navMsg() {
			this.$router.push({
				path: '/msg'
			});
		},
		navUserHome() {
			this.$router.push({
				path: '/user/home'
			});
		},
		navAuth() {
			this.$router.push({
				path: '/auth'
			});
		},
		navUrl(url) {
			if (this.isImageURL(url)) {
				ImagePreview({
					images: [this.$store.getters.oss(url)]
				});
			} else {
				window.location.href = url;
			}
		},
		isImageURL(url) {
			const imgExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
			const urlExtension = url
				.split('.')
				.pop()
				.toLowerCase();
			return imgExtensions.includes(urlExtension);
		},
		onSelect(e) {
			console.log(e.value);
			this.$set(this.filters, 'awardtype', e.value);
			this.onRefresh();
		},
		// 分类选择确认
		onSubConfim(e) {
			console.log(e);
			this.$store.dispatch('setMajor', e);
			this.onRefresh();
		},
		openSubjectSelect() {
			this.$refs['subjectSelect'].open();
		},
		navAddAsk() {
			this.$router.push({
				path: '/ask/add'
			});
		},
		tabChange(index) {
			this.tabIndex = index;
			this.onRefresh();
		},
		async onLoad() {
			console.log('onLoad');
			// if (!this.majorInfo.major_id) {
			// 	return;
			// }
			this.loading = true;
			this.page++;
			let res = await this.$service.post('home/index_v2', {
				// 最新 (newest);已解答 (answered);关注 (followed);待解答 (unanswered)
				// 1=关注,2=推荐,3=悬赏,4=最新
				type: this.tabIndex,
				major_id: this.majorInfo.major_id,
				filters: this.filters,
				page: this.page,
				pageSize: this.pageSize
			});

			if (res.data.data.length) {
				this.list = this.list.concat(res.data.data);
				this.loading = false;
				if (res.data.data.length < this.pageSize) {
					this.finished = true;
				}
			} else {
				this.finished = true;
			}

			this.refreshing = false;
			this.$nextTick(() => {
				this.stickyContainer = this.$refs['page-list'];
			});
		},
		onRefresh() {
			console.log('onRefresh');
			this.page = 0;
			this.list = [];
			this.finished = false;
			this.refreshing = false;
			this.onLoad();
		}
	}
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
